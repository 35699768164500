import '../css/fraudsniffr-2-font.css';
import '../css/icomon.css';
import '../less/reset.less';
import '../less/style.less';

import './filter/index.js';
import './upload/index.js';
import AddressAutocomplete from "./address-autocomplete";

import aload from 'aload';
import reportValidity from 'report-validity';

window.modalHandler = function( url, title, data, target )
{
    var target  = target || '#default_modal';
    var loading = '<div class="text-center">LOADING</div>';
    var modal   = $( target );

    modal.find( '.modal-body' ).html( loading );
    modal.find( '.modal-header h3' ).html( title );
    modal.find( '.modal-header-buttons' ).html( '' );
    modal.modal( 'show' );

    if ( typeof data == 'string' )
        var data = JSON.parse( data );

    $.ajax({
        url: url,
        type: 'POST',     
        async: true,  
        data: {
            data
        }
    }).then( function( data, status ) {
        if ( typeof data == 'object' ) {
            modal.find( '.modal-body' ).html( data.body );
            modal.find( '.modal-header-buttons' ).html( data.buttons )
        } else
            modal.find( '.modal-body' ).html( data );
    });   
}

window.updatePreview = function( input, target, asImg )
{
    let setPreview = ( data ) => {
        let ts = document.querySelectorAll( target );

        ts.forEach( t => {
            if ( asImg )
                return t.innerHTML = `<img src="${ data }" alt="" class="img" />`;
            else 
                return t.style.backgroundImage = `url(${ data })`;
        });
    };
    

    if ( !input.files.length ) 
        return setPreview( '' );

    let fr = new FileReader();
    
    fr.onload = ( evt ) => {
        let data = evt.target.result;
        setPreview( data );
    };
    
    fr.readAsDataURL( input.files[0] );
};

const app = window.onload = function() {

    aload();
    window.aload = aload;
    window.reportValidity = reportValidity;

    // var ckEditors = document.querySelectorAll( '.ckeditor-elm' );
    //     ckEditors.forEach( (elm, k) => {
    //         window._ckEditors = [];
    //         CKEDITOR.replace( elm, {
    //         filebrowserUploadUrl: '/claimant/upload_image_cke',
    //         filebrowserUploadMethod: 'form',
    //         contentsCss: [
    //             document.getElementById('css-bootstrap').href,
    //             document.getElementById('css-font').href,
    //             document.getElementById('css-app').href,
    //         ],
    //         on: {
    //             instanceReady: ( evt ) =>
    //             {
    //                 let editor = evt.editor;
    //                 editor._isDirty = false;
    //                 editor._saveTimeout = null;
    //                 _ckEditors.push( editor );
    //                 // console.log("ins "+k);
    //             },
    //             change: ( evt ) =>
    //             {
    //                 let editor = evt.editor;
    //                 editor._isDirty = true;
    //                 clearTimeout( editor._saveTimeout );
    //                 window.addEventListener( 'beforeunload', warnBeforeLeave );
    //                 editor._saveTimeout = setTimeout( () => {
    //                     saveEditorData( editor );
    //                 }, 30000 );
    //             }
    //         },
    //         toolbarGroups: [
    //             { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    //             { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    //             { name: 'links', groups: [ 'links' ] },
    //             { name: 'insert', groups: [ 'Image', 'Table' ] },
    //             { name: 'forms', groups: [ 'forms' ] },
    //             { name: 'tools', groups: [ 'tools' ] },
    //             { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    //             { name: 'others', groups: [ 'others' ] },
    //             { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    //             { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    //             { name: 'styles', groups: [ 'styles' ] },
    //             { name: 'colors', groups: [ 'colors' ] },
    //             { name: 'about', groups: [ 'about' ] },
    //             { name: 'Image', groups: [ 'image' ] }
    //         ],
    //         removeButtons: 'Underline,Cut,Copy,Paste,PasteText,PasteFromWord,Scayt,Anchor,Source,About',
    //     });
    // });


    window.warnBeforeLeave = ( evt ) => {
        evt.preventDefault();
        evt.returnValue = '';
        return "There are unsaved changes in your editor. If you leave the page now those changes will be lost. Do you confirm leaving the page?";
    };

    // window.saveEditorData = ( editor ) => {
    //     if ( !editor._isDirty )
    //         return;
    //
    //     editor.updateElement();
    //
    //     let form = editor.element.$.parentNode;
    //
    //     form.previousElementSibling.innerHTML = editor.getData();
    //
    //     jQuery.post(
    //         form.action,
    //         jQuery( form ).serialize()
    //     ).then( resp => {
    //         editor._isDirty = false;
    //         window.removeEventListener( 'beforeunload', warnBeforeLeave );
    //
    //         if ( jQuery( "#version" ).length ) {
    //             jQuery( "#version" ).html( resp.version );
    //             jQuery( "#editedBy" ).html( resp.editedBy );
    //             jQuery( "#editedDate" ).html( resp.editedDate );
    //         }
    //     });
    // };

    // window.cancelEditing = ( editor ) => {
    //     editor._isDirty = false;
    //     editor.setData( editor.element.$.value );
    //     window.removeEventListener( 'beforeunload', warnBeforeLeave );
    //     clearTimeout( editor._saveTimeout );
    // };

    const selector = document.querySelectorAll('.check_all_selector');
    selector.forEach( ( elm, k ) => {
        elm.addEventListener('change',function(e){
            var obj = e.target;
            
            var checks = document.querySelectorAll('.checkbox[name^="'+obj.name+'"]');
            checks.forEach((check)=>{
                check.checked = obj.checked;
            });
            
        });
    });


    if ( typeof moment != 'undefined' ) 
        jQuery( '.daterangepicker-range' ).daterangepicker({
            autoUpdateInput: false,
            // timePicker: true,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
        }).on( 'apply.daterangepicker', function( ev, picker ) {
            $( this ).val( picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY') );

            if ( this.onchange )
                this.onchange.apply(this, ev)
        });

}

app;
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

window.addressAutocomplete = AddressAutocomplete(process.env.MAPBOX_ACCESS_TOKEN);