const app = window.onload = function() {
    const drop_zone = document.getElementById('drop_zone');

    drop_zone.addEventListener('dragover',function(ev){
        console.log('ev over',ev);
        ev.preventDefault();
    });

    drop_zone.addEventListener('drop',function(ev){
        console.log('drop',ev);
        ev.preventDefault();
  
        if (ev.dataTransfer.items) 
        {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') 
                {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    console.log('... file[' + i + '].name = ' + file.name);
                }
            }
        } else 
        {
        // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
            }
        }
    })
}

export default app;
