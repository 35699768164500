document.addEventListener( 'DOMContentLoaded', () => {

    if ( !document.forms.filter )
        return;

    document.forms.filter.addEventListener( 'reset', function ( evt ) {
        window.location.href = document.forms.filter.action.replace( /\/\?(.*)/, '' );
    });

    document.querySelector( '.filter-collapse' ).addEventListener( 'click', function ( evt ) {
        var col = evt.target.parentNode;
        var col1 = document.querySelector('.filter-collapse').parentNode.nextElementSibling;
        while ( !col.classList.contains( 'col-1' ) && !col.classList.contains( 'col-3' ) )
            col = col.parentNode;
            
            var cl = col.classList;
            col.style.transition = 'all .4s';
            col.style.overflow   = 'hidden';
            
            cl.toggle( 'col-1' );
            cl.toggle( 'col-3' );
            
        while ( !col1.classList.contains( 'col-11' ) && !col1.classList.contains( 'col-9' ) )
            col1 = col1.parentNode;
        
      
            col1.classList.remove("mystyle");
            var cl1 = col1.classList;
            col1.style.transition = 'all .4s';
            col1.style.overflow   = 'hidden';
                
                
            cl1.toggle( 'col-11' );
            cl1.toggle( 'col-9' );

    });

});