import {MapboxAutofill, SessionToken} from '@mapbox/search-js-core';

export default function (accessToken) {
    const autofill     = new MapboxAutofill({ accessToken });
    const sessionToken = new SessionToken();

    const suggest = search => {
        return autofill.suggest(search, {
            sessionToken,
            limit: 5,
            country: 'US'
        });
    }

    return {
        suggest
    }
}
